/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
// import 'p5/lib/addons/p5.dom';
// import 'p5/lib/addons/p5.sound';
// import p5 from 'p5';
// import p5s from 'p5/lib/addons/p5.sound';
import Tetris from './tetris';
// import UpIcon from '../images/icons/keyboard_arrow_down-24px.svg';
// import UpIcon from '../images/icons/keyboard_arrow_down-black-48dp/2x/baseline_keyboard_arrow_down_black_48dp.png';

export default function sketch(p) {
    // Libraries
    const margin = 10;
    const buttonSize = 40;
    const cellSize = 20;
    const numColumns = 7;
    const numRows = 13;
    const boardHeight = numRows * cellSize;
    const boardWidth = numColumns * cellSize;
    let doDraw = true;
    let makeStep = true;
    let fallDown = false;
    // let board = [];
    let tetris;
    let counter = 0;
    let speed = 30;

    let buttonL;
    let buttonU;
    let buttonR;
    let buttonD;

    let monoSynth;

    p.preload = () => {
        buttonL = p.loadImage('/../icons/baseline_keyboard_arrow_left_black_48dp.png');
        buttonU = p.loadImage('/../icons/baseline_keyboard_arrow_up_black_48dp.png');
        buttonR = p.loadImage('/../icons/baseline_keyboard_arrow_right_black_48dp.png');
        buttonD = p.loadImage('/../icons/baseline_keyboard_arrow_down_black_48dp.png');
    };

    p.setup = () => {
        p.createCanvas(boardWidth, boardHeight + margin + 2 * buttonSize);
        p.frameRate(30);
        p.smooth();
        tetris = new Tetris(numRows, numColumns);
        // monoSynth = p5s.MonoSynth();

        // buttonL = p
        //     .createImg('/../icons/baseline_keyboard_arrow_left_black_48dp.png')
        //     .size(boardWidth / 3, boardWidth / 3)
        //     .mousePressed(moveLeft);
        // buttonU = p
        //     .createImg("/../icons/baseline_keyboard_arrow_up_black_48dp.png")
        //     .size(boardWidth / 3, boardWidth / 3)
        //     .mousePressed(rotateTet);
        // buttonR = p
        //     .createImg("/../icons/baseline_keyboard_arrow_right_black_48dp.png")
        //     .size(boardWidth / 3, boardWidth / 3)
        //     .mousePressed(moveRight);
        // .size(boardWidth / 3);
        // console.log(buttonL);
        // img = p.loadImage("../../src/images/icons/keyboard_arrow_down-black-48dp/2x/baseline_keyboard_arrow_down_black_48dp.png");
        // buttonL.position(19, 19);
        // buttonM = p.createButton('~').size(boardWidth / 3, 30);
        // buttonR = p.createButton('->').size(boardWidth / 3, 30);
        // buttonL.size(boardWidth / 3, 20);
        // buttonR = p.createButton('->');
        // button.position(19, 19);
        // button.mousePressed(changeBG);
        // p.noStroke();
    };

    p.draw = () => {
        if (makeStep || fallDown) {
            counter = 0;
            const hasLanded = tetris.step();
            if (hasLanded) fallDown = false;
            doDraw = true;
        }
        // if (makeStep) tetris.step();
        if (doDraw) {
            p.background(220); // 209, 208, 166
            p.fill(255);
            p.noStroke();
            p.rect(0, boardHeight, boardWidth, margin + 2 * buttonSize);
            drawBoard(tetris.boardWithTet);
            drawButtons();
            doDraw = false;
            // p.image(img, 0, 0);
        }
        counter += 1;
        makeStep = counter === speed;
    };

    function playSynth() {
        p.userStartAudio();
        const note = p.random(['Fb4', 'G4']);
        // note velocity (volume, from 0 to 1)
        const velocity = p.random();
        // time from now (in seconds)
        const time = 0;
        // note duration (in seconds)
        const dur = 1 / 6;
        monoSynth.play(note, velocity, time, dur);
    }

    function drawButtons() {
        p.image(buttonU, boardWidth / 2 - buttonSize / 2, boardHeight + margin, buttonSize, buttonSize);
        p.image(buttonD, boardWidth / 2 - buttonSize / 2, boardHeight + margin + buttonSize, buttonSize, buttonSize);
        p.image(buttonL, boardWidth / 2 - 1.5 * buttonSize, boardHeight + margin + buttonSize, buttonSize, buttonSize);
        p.image(buttonR, boardWidth / 2 + 0.5 * buttonSize, boardHeight + margin + buttonSize, buttonSize, buttonSize);
    }

    p.keyPressed = () => {
        if (p.keyCode === p.LEFT_ARROW) {
            moveLeft();
        } else if (p.keyCode === p.RIGHT_ARROW) {
            moveRight();
        } else if (p.keyCode === p.UP_ARROW) {
            rotateTet();
        } else if (p.keyCode === p.DOWN_ARROW) {
            makeStep = true;
        } else if (p.keyCode === 32) {
            fallDown = true;
        }
        return false;
    };

    p.mousePressed = () => {
        if (
            p.mouseX > boardWidth / 2 - buttonSize / 2 &&
            p.mouseX < boardWidth / 2 + buttonSize / 2 &&
            p.mouseY > boardHeight + margin &&
            p.mouseY < boardHeight + margin + buttonSize
        ) {
            rotateTet();
        } else if (
            p.mouseX > boardWidth / 2 - buttonSize / 2 &&
            p.mouseX < boardWidth / 2 + buttonSize / 2 &&
            p.mouseY > boardHeight + margin + buttonSize &&
            p.mouseY < boardHeight + margin + 2 * buttonSize
        ) {
            makeStep = true;
        } else if (
            p.mouseX > boardWidth / 2 - 1.5 * buttonSize &&
            p.mouseX < boardWidth / 2 - 0.5 * buttonSize &&
            p.mouseY > boardHeight + margin + buttonSize &&
            p.mouseY < boardHeight + margin + 2 * buttonSize
        ) {
            moveLeft();
        } else if (
            p.mouseX > boardWidth / 2 + 0.5 * buttonSize&&
            p.mouseX < boardWidth / 2 + 1.5 * buttonSize &&
            p.mouseY > boardHeight + margin + buttonSize&&
            p.mouseY < boardHeight + margin + 2 * buttonSize
        ) {
            moveRight();
        }
    };

    function moveLeft() {
        // playSynth();
        if (
            tetris.currentCol > 0 &&
            tetris.currentRow >= tetris.lowestFreeRows[tetris.currentCol - 1]
        ) {
            tetris.currentCol -= 1;
            tetris.putTetOnBoard(false);
            doDraw = true;
        }
    }

    function moveRight() {
        if (
            tetris.currentCol + tetris.currentTetromino[0].length < tetris.numColumns &&
            tetris.currentRow >= tetris.lowestFreeRows[tetris.currentCol + tetris.currentTetromino[0].length]
        ) {
            tetris.currentCol += 1;
            tetris.putTetOnBoard(false);
            doDraw = true;
        }
    }

    function rotateTet() {
        let tmpRotation = tetris.currentRotation + 1;
        tmpRotation %= tetris.tetNumRotations[tetris.currentTetrominoIndex];
        const tmpTet = tetris.tetrominoShapes[tetris.currentTetrominoIndex][tmpRotation];
        if (tetris.currentCol + tmpTet[0].length <= tetris.numColumns) {
            tetris.currentRotation = tmpRotation;
            tetris.currentTetromino = tmpTet;
            tetris.putTetOnBoard(false);
            doDraw = true;
        }
    }

    function drawBoard(board) {
        for (let col = 0; col < numColumns; col += 1) {
            for (let row = 0; row < numRows; row += 1) {
                const f = board[row][col];
                if (f > 0) {
                    f === 1 ? p.fill(30) : p.fill(173, 39, 40); // 180, 30, 20
                    const x = col * cellSize;
                    const y = boardHeight - (row + 1) * cellSize;
                    p.rect(x, y, cellSize, cellSize);
                }
            }
        }
    }
}
