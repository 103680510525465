import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import Sketch from '../components/sketch';
import sketch from '../scripts/tetris/sTetris';
import SimpleDateConverter from '../components/simpleDateConverter';

export const pageQuery = graphql`
    query blogIndex {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
            edges {
                node {
                    id
                    excerpt
                    frontmatter {
                        title
                        slug
                        date
                    }
                }
            }
        }
    }
`;

const BlogIndex = ({ data }) => {
    const { edges: posts } = data.allMdx;
    return (
        <Layout>
            {/* <h1>Awesome MDX Blog</h1> */}
            <ul>
                {posts.map(({ node: post }) => (
                    <Link to={`/posts/${post.frontmatter.slug}`} style={{ textDecoration: 'none' }}>
                        <li key={post.frontmatter.date}>
                            <div className="blogIndexEntry">
                                <SimpleDateConverter date={post.frontmatter.date} />
                                <p>{post.frontmatter.title}</p>
                            </div>
                            {/* <p>{post.excerpt}</p> */}
                        </li>
                    </Link>
                ))}
            </ul>

            {/* <p>I know there isn't much content here yet :( How about playing some Tetris until there's more?
            </p>
            <Sketch sketch={sketch} /> */}
        </Layout>
    );
};

export default BlogIndex;
