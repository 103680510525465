import React, { Component } from 'react';

const SimpleDateConverter = (props) => {
    const d = new Date(props.date);
    const day = d.getDate();
    return (
        <p className="dateauthor">
            {d.toLocaleDateString('default', {month: "long"}).toUpperCase()} {day.toString()},  {d.getFullYear()}
        </p>
    );
};

export default SimpleDateConverter;
